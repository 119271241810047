import { createContext, useEffect, useMemo, useState } from "react";
import { getItemJson, saveItemJson } from "../../helpers/sessionstorage.helper";

export const AuthContext = createContext();

const AuthProvider = props => {
    const { children } = props;

    const [user, setuser] = useState(getItemJson('faculty-session') || null);

    useEffect(() => {
        try {
            saveItemJson('faculty-session', user)
        } catch (error) {
            sessionStorage.clear()
            console.error(error)
        }
    }, [user])

    const contextValue = useMemo(() => {
        return ({
            user,
            login(user){
                setuser(user)
            },
            logout(){
                sessionStorage.clear()
                setuser(null)
            },
            isLogged(){
                return !!user
        }
        })    
    }, [user])

    return <AuthContext.Provider value={contextValue}>
        {children}
    </AuthContext.Provider>
}

export default AuthProvider