import * as encode from "nodejs-base64-encode"

export const getItemJson = (name) => {
    const storage = sessionStorage.getItem(name)
    
    if (storage !== null) {
        const descript = encode.decode(storage, 'base64')
        return JSON.parse(descript)
    }
    return null
}

export const saveItemJson = (name,data) => {
    const storageHash = encode.encode(JSON.stringify(data), 'base64')
    const escript = sessionStorage.setItem(name , storageHash)
    return escript
}