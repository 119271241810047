import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getItemJson } from "../helpers/sessionstorage.helper";
import useAuth from "../hooks/useAuth";

const PublicRoute = (props) => {
	const { component: Component, ...rest } = props;
	const user = useAuth()
	return (
		<>
			<Route
				{...rest}
				render={(props) =>
					user.isLogged() ? <Redirect to="/home" /> : <Component {...props} />
				}
			/>
		</>
	);
};

export default (PublicRoute);
