import axios from "axios";

const REPOURL = process.env.REACT_APP_REPOAPI

const FACULTY = "/v1/repo_aden/faculty"

export const getDataUserService = async (id) => {
    const URL = `${REPOURL}${FACULTY}/info_personal/${id}`

    try {
        const result = await axios.get(URL)
        return result.data
    } catch (error) {
        return {
            error: error.response.data,
            status: true
        }
    }
}


export const getTypeDocumentService = async () => {
    const URL = `${REPOURL}${FACULTY}/tipo_documento_unico`

    try {
        const result = await axios.get(URL)
        return result.data
    } catch (error) {
        return {
            error: error.response.data,
            status: true
        }
    }
}

export const getCvService = async (id) => {
    const URL = `${REPOURL}${FACULTY}/cv/${id}`

    try {
        const result = await axios.get(URL)
        return result.data
    } catch (error) {
        return {
            error: error.response.data,
            status: true
        }
    }
}

export const getTypesDocumentsService = async () => {
    const URL = `${REPOURL}${FACULTY}/tipo_documentacion`

    try {
        const result = await axios.get(URL)
        return result.data
    } catch (error) {
        return {
            error: error.response.data,
            status: true
        }
    }
}

export const getExperiencieAreaService = async () => {
    const URL = `${REPOURL}${FACULTY}/areas_experiencia`

    try {
        const result = await axios.get(URL)
        return result.data
    } catch (error) {
        return {
            error: error.response.data,
            status: true
        }
    }
}

export const getMaximumRatingService = async () => {
    const URL = `${REPOURL}${FACULTY}/maxima_calificacion`

    try {
        const result = await axios.get(URL)
        return result.data
    } catch (error) {
        return {
            error: error.response.data,
            status: true
        }
    }
}

export const postSaveProfileService = async (id,data) => {
    const URL = `${REPOURL}${FACULTY}/modificar_datos/${id}`

    try {
        const result = await axios.post(URL,data)
        return result.data
    } catch (error) {
        return {
            error: error.response.data.detail,
            status: true
        }
    }
}

export const postDocumentService = async (id ,data ) => {
    const URL = `${REPOURL}${FACULTY}/subir_documento/${id}`

    try {
        const result = await axios.post(URL,data)
        return result.data
    } catch (error) {
        return {
            error: error.response.data.detail,
            status: true
        }
    }
}

// export const postDocumentService = async (id ,data ) => {
//     const URL = `${REPOURL}${FACULTY}/subir_documento/${32922}`

//     try {
//         const result = await axios.post(URL,data)
//         return result.data
//     } catch (error) {
//         return {
//             error: error.response.data.detail,
//             status: true
//         }
//     }
// }