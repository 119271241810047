import { InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useSnackBar } from "../../../../hooks/useSnackBar";
import { getExperiencieAreaService, getMaximumRatingService, postSaveProfileService } from "../../../../services/repositorio.api";
import Loading from "../../../common/Loading";
import { SnackBarGenerico } from "../../../common/SnackBarGenerico";

const FormAcademic = props => {
    const { data, id, repo_id } = props;
    // State
    const [formData, setformData] = useState(null)
    const [experienceArea , setExperienceArea] = useState(null)
    const [maximumRating , setMaximumRating] = useState(null)
    const [loading, setloading] = useState(null)
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    // Effects
    useEffect(() => {
        if (formData === null) {
            setformData(data)
        }
    }, [formData])

    useEffect(() => {
        if (experienceArea === null) {
            getExperiencieArea()
        }
    }, [experienceArea])

    useEffect(() => {
        if (maximumRating === null) {
            getMaximumRating()
        }
    }, [maximumRating])

    // Functions

    const getExperiencieArea = async () => {
        const response = await getExperiencieAreaService()
        setExperienceArea(response)
    }

    const getMaximumRating = async () => {
        const response = await getMaximumRatingService()
        setMaximumRating(response)
    }

    const handleSubmit = async () => {
        setloading(true)
        const data = validateInputs(formData)
        const response = await postSaveProfileService(repo_id, data)
        if (response.status) {
            openSnackBar(
                "error",
                response.error
            );
        }
        else{
            openSnackBar(
                "success",
                response
            );
        }
        setloading(null)
    }

    const validateInputs = (data) => {
        const newData = {}

        if(data.titulo_universitario) newData.titulo_universitario = data.titulo_universitario
        if(data.universidad) newData.universidad = data.universidad
        if(data.max_calificacion_academica_id) newData.max_calificacion_academica_id = data.max_calificacion_academica_id
        if(data.titulo_maximo) newData.titulo_maximo = data.titulo_maximo
        if(data.institucion_maxima) newData.institucion_maxima = data.institucion_maxima
        if(data.anos_en_industria) newData.anos_en_industria = data.anos_en_industria
        if(data.areas_experiencia_ids) newData.areas_experiencia_ids = [data.areas_experiencia_ids]

        return newData
    }

    if (formData === null) {
        return <Loading />
    }

    return <><Container>
        <Col>
            <FormGroup>
                <TextFieldFake
                    value={formData.titulo_universitario === false ? "" : formData.titulo_universitario}
                    label="Título universitario"
                    onChange={e => setformData(state => ({ ...state, titulo_universitario: e.target.value }))}
                />
            </FormGroup>

            <FormGroup>
                <TextFieldFake
                    value={formData.universidad === false ? "" : formData.universidad}
                    label="Universidad"
                    onChange={e => setformData(state => ({ ...state, universidad: e.target.value }))}
                />
            </FormGroup>

            <FormGroup>
                <InputLabel>Máxima calificación académica</InputLabel>
                <SelectFake
                    value={formData.max_calificacion_academica_id}
                    onChange={e => setformData(state => ({ ...state, max_calificacion_academica_id: e.target.value }))}
                >
                    {maximumRating && (
                        maximumRating.map(item => <MenuItem value={item.id}>{item.nombre}</MenuItem>)
                    )}
                </SelectFake>
            </FormGroup>

            <FormGroup>
                <InputLabel>Áreas de experiencia</InputLabel>
                <SelectFake
                    value={formData.areas_experiencia_ids}
                    onChange={e => setformData(state => ({ ...state, areas_experiencia_ids: e.target.value }))}
                >
                    {experienceArea && (
                        experienceArea.map(item => <MenuItem value={item.id}>{item.nombre}</MenuItem>)
                    )}
                </SelectFake>
            </FormGroup>
            {/* <FormGroup>
                <TextFieldFake
                    value={formData.max_calificacion_academica_id === false ? "" : formData.max_calificacion_academica_id}
                    label="Máxima calificación académica"
                    onChange={e => setformData(state => ({ ...state, max_calificacion_academica_id: e.target.value }))}
                />
            </FormGroup> */}


        </Col>
        <Col>
            <FormGroup>
                <TextFieldFake
                    value={formData.titulo_maximo === false ? "" : formData.titulo_maximo}
                    label="Título máximo"
                    onChange={e => setformData(state => ({ ...state, titulo_maximo: e.target.value }))}
                />
            </FormGroup>

            <FormGroup>
                <TextFieldFake
                    value={formData.institucion_maxima === false ? "" : formData.institucion_maxima}
                    label="Institución máxima"
                    onChange={e => setformData(state => ({ ...state, institucion_maxima: e.target.value }))}
                />
            </FormGroup>

            <FormGroup>
                <TextFieldFake
                    value={formData.anos_en_industria === false ? "" : formData.anos_en_industria}
                    label="Años en la industria"
                    onChange={e => setformData(state => ({ ...state, anos_en_industria: e.target.value }))}
                />
            </FormGroup>

           
        </Col>
    </Container>
        <ZoneSubmit>
            {loading === null ? 
            (<ButtonFake disabled={loading !== null || !formData} onClick={handleSubmit}>GUARDAR</ButtonFake>)
            :
            (<Loading/>)
            }
        </ZoneSubmit>
        <SnackBarGenerico
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </>
}

export default FormAcademic;

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
`

const Col = styled.div`
    width: 50%;
    height: 100%;
    padding: 1rem;
`

const TextFieldFake = styled(TextField)`
    width: 100%;
`

const SelectFake = styled(Select)`
    width: 100%;
    margin-top: 15px;
`

const FormGroup = styled.div`
    padding: 15px;
`

const ZoneSubmit = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ButtonFake = styled.button`
    color: white;
    background-color: #de4d2c;
    padding: 9px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 3px;
    transition: all .3s ease;
    cursor: pointer;
    :disabled{
        background-color: #404040;
        cursor: inherit;
    }
    :hover{
        background-color: #a33e27;
        :disabled{
            background-color: #404040;
            cursor: inherit;
    }
    }
`