import React from "react";
import { useHistory } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const Logout = () => {

    const history = useHistory()

    const user = useAuth()
    user.logout()
    history.push("../")

    return null
}

export default Logout;