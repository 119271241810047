import { useState } from "react";

export const useViewPort = () => {

    const [width, setWidth] = useState(window.screen.width)
    const [height, setHeight] = useState(window.screen.height)

    window.addEventListener('resize', () => {
        setWidth(window.screen.width)
        setHeight(window.screen.height)
    });


    return { width, height }
}