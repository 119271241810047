import React, { useEffect } from "react";
import { Redirect, Route } from "react-router-dom";

import Layout from "../components/shared/layout/Layout";
import { getItemJson } from "../helpers/sessionstorage.helper";
import useAuth from "../hooks/useAuth";

const PrivateRoute = (props) => {
	const { component: Component, ...rest } = props;
	const user = useAuth()
	// RETURN
	return (
		<Route
			{...rest}
			component={(props) =>
				user.isLogged() ? (
					<Layout>
						<Component {...props} />
					</Layout>
				) : (
					<Redirect to="/auth/login" />
				)
			}
		/>
	);
};


export default (PrivateRoute);
