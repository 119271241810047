import { Dialog } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import TableGen from "../../../common/Table";
import FormUploadDocument from "./FormUploadDocument";

const Documentation = (props) => {
    const { data, repo_id } = props

    const [open, setOpen] = useState(false);
  

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = (value) => {
        setOpen(false);
    };

    const columns = [
        { id: 'id_tipo_documentacion', label: 'Cod. ID' },
        { id: 'institucion', label: 'Institución' },
        { id: 'tipo', label: 'Tipo' },
        { id: 'titulo', label: 'Titulo' },
    ]

    return (<Container>
        <ButtonFake variant="outlined" color="primary" onClick={handleClickOpen}>
            Cargar documento
        </ButtonFake>
        <Dialog maxWidth="xl" open={open} onClose={handleClose} >
            <ContainerDialog>
                <FormUploadDocument repo_id={repo_id} handleClose={handleClose}/>
            </ContainerDialog>
        </Dialog>
        <TableGen columns={columns} rows={data} pageSize={15} />
    </Container>)
}

export default Documentation


const ContainerDialog = styled.div`
    padding: 1rem;
`
const Container = styled.div`
    width: 100%;
    padding: 1rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
`

const ButtonFake = styled.button`
    margin-bottom: 25px;
    color: white;
    background-color: #de4d2c;
    padding: 9px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 3px;
    transition: all .3s ease;
    cursor: pointer;
    :disabled{
        background-color: #404040;
        cursor: inherit;
    }
    :hover{
        background-color: #a33e27;
        :disabled{
            background-color: #404040;
            cursor: inherit;
    }
    }
`