import { useState } from "react"

export const useSnackBar = () => {
  const [snackBar, setSnackBar] = useState({ show: false, variant: 'success', message: '' })

  const closeSnackBar = () => {
    setSnackBar({ ...snackBar, show: false });
  };
  const openSnackBar = (variant = 'success', message = '') => {
    setSnackBar({ ...snackBar, variant, message, show: true });
  };
  
  return [snackBar, closeSnackBar, openSnackBar]
}
