import React from "react";
import styled from "styled-components";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AdditionalInfo from "../../molecules/home/AdditionalInfo";
import SisData from "../../molecules/home/SisData";
import FormReport from "../../molecules/home/FormReport";

const DialogInfo = (props) => {
    const { data, handleClose } = props

    // console.log(data);
    
    return <Container>
        <Header>
            <Title>Detalles</Title>
            <Close onClick={handleClose}>✖</Close>
        </Header>
        <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>Información Adicional</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <AdditionalInfo note={data.notes} date={data.date_order} id={data.name}/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Ver alumnos en SIS</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SisData id={data.name}/>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography>Reportar comentario</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <FormReport id={data.name} contact={data.contacto} typeId={data.type_id}/>
        </AccordionDetails>
      </Accordion>
    </Container>
}

export default DialogInfo;

const Container = styled.div`
    margin: 1rem;
`

const Header = styled.div`
    width: 100%;
    height: 40px;
    border-bottom: #de4d2c 2px solid;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Title = styled.h4`
    color: #de4d2c;
    font-size: 16px;
    font-weight: bolder;
`

const Close = styled.div`
    color: #de4d2c;
    font-size: 16px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
`