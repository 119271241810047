import { Tooltip, withStyles } from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

const ItemAside = (props) => {
    const { icon, title, to } = props

    const history = useHistory()

    return (<Href to={to}>
        <RedTooltip arrow title={title} placement="right">
            <Container active={history.location.pathname === to}>{icon}</Container>
        </RedTooltip>
    </Href>
    )
}

export default ItemAside;

const RedTooltip = withStyles((theme) => ({
    arrow: {
        color: "#303030",
    },
    tooltip: {
        backgroundColor: "#303030",
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 14,
        padding: "0.2em 0.5em",
    },
}))(Tooltip);

const Container = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease;
    svg, span {
            color: #fafafa;
    }
    span {
        color: #bfbfbf;
    }

    :hover {
        background-color: #545454;
        border-right: 2px solid #fafafa;
    }

    ${props => props.active ? (
        `
        background-color: #545454;
        border-right: 2px solid #de4d2c;
        width: 102%;
        `
    ):
        null}
    
`

const Href = styled(Link)`

`