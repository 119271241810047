import React from "react";
import styled from "styled-components";

const DataCard = (props) => {

    const { img, component } = props

    return <Data>
        <LeftWrapper>
            <Img src={img} />
        </LeftWrapper>
        <RightWrapper>
            {component}
        </RightWrapper>
    </Data>
}

export default DataCard

const Data = styled.div`
    width: 32.5%;
    height: 100%;
    /* background-color: red; */
    border: 1.5px solid #de4d2c;
    display: flex;
    -webkit-box-shadow: 5px 5px 15px 5px #B4B4B4; 
    box-shadow: 5px 5px 15px 5px #B4B4B4;
    @media screen and (max-width: 768px) {
        width: 100%;
        margin:  .5rem;
    }
`

const LeftWrapper = styled.div`
    width: 30%;
    height: 100%;
    background-color: #de4d2c;
    display: flex;
    justify-content: center;
    align-items: center;
`

const RightWrapper = styled.div`
    width: 70%;
    height: 100%;
    padding: 5px;
`

const Img = styled.img`
    width: 50%;
    height: auto;
`