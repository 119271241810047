import { Tooltip, withStyles } from "@material-ui/core";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

const ItemAsideMobile = (props) => {
    const { title, to, type = false } = props

    const history = useHistory()

    if (!!type) {
        return (
            <Ahref target="_blank" href={to}>
                <Container active={history.location.pathname === to}><Name>{title}</Name></Container>
            </Ahref>
        )
    }

    return (<Href to={to}>
        <Container active={history.location.pathname === to}><Name>{title}</Name></Container>
    </Href>
    )
}

export default ItemAsideMobile;


const Container = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease;
    svg, span {
            color: #fafafa;
    }
    span {
        color: #bfbfbf;
    }

    ${props => props.active ? (
        `
        background-color: #545454;
        border: 1px solid #de4d2c;
        width: 102%;
        `
    ):
        null}
    
`

const Href = styled(Link)`
    text-decoration: none;
`
const Ahref = styled.a`
    text-decoration: none;
`

const Name = styled.div`
    font-weight: 600;
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    text-transform: uppercase;
`