import React, { useEffect } from "react";
import styled from "styled-components";
import logo from "../../../assets/images/logo-aden-faculty.png";
import MenuIcon from '@material-ui/icons/Menu';
import { useViewPort } from "../../../hooks/useViewPort";

const Navbar = (props) => {

    const { showMenu } = props

    const { width } = useViewPort()

    return <Container>
        <Logo src={logo}/>
        {width < 768 ? <Menu onClick={showMenu}><MenuIcon/></Menu>: null}
    </Container>
}

export default Navbar;

const Container = styled.nav`
    width: calc( 100% - 80px );
    height: 60px;
    position: fixed;
    left: 80px;
    top: 0;
    background-color: #303030;
    display: flex;
    justify-content: end;
    align-items: center;
    -webkit-box-shadow: 0px 3px 11px -3px #313131; 
    box-shadow: 0px 3px 11px -3px #313131;
    @media screen and (max-width: 768px) {
        width: 100%;
        left: 0;
        justify-content: space-between;
    }
`
const Logo = styled.img`
    height: 40px;
    width: auto;
    margin-right: 25px;
    @media screen and (max-width: 768px) {
        height: 30px;
        margin-left: 25px
    }
`

const Menu = styled.div`
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-right: 1rem;
    svg{
        color: white;
    }
`