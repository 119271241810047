export default function setupAxios(axios, store) {
	axios.interceptors.request.use(
		(config) => {
			const auth = store
			if (auth !== null) {
				if (auth.token !== undefined) {
					config.headers.Authorization = `Bearer ${auth.token}`;
				}
			}
			return config;
		},
		(err) => Promise.reject(err)
	);

	axios.interceptors.response.use(
		(res) => res,
		(error) => {
			const { response } = error;
			if (response) {
				if (response.data?.code == "expired_token") {
					sessionStorage.clear()
				}
			}

			return Promise.reject(error);
		}
	);
}
