import React from "react";
import styled from "styled-components";
import FormDocument from "../../molecules/profile/FormDocument";

const FormUploadDocument = props => {
    const { handleClose, repo_id } = props

    return <Modal>
        <Header>
            <Title>Cargar documento</Title>
            <Close onClick={handleClose}>✖</Close>
        </Header>
        <Body>
            <FormDocument repo_id={repo_id} handleClose={handleClose}/>
        </Body>
    </Modal>
}

export default FormUploadDocument;

const Modal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Header = styled.div`
    min-width: 400px;
    width: 100%;
    height: 40px;
    border-bottom: #de4d2c 2px solid;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

const Title = styled.h4`
    color: #de4d2c;
    font-size: 16px;
    font-weight: bolder;
`

const Close = styled.div`
    color: #de4d2c;
    font-size: 16px;
    font-weight: bolder;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    cursor: pointer;
`

const Body = styled.div`
    width: auto;
`