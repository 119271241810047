import { Tooltip, withStyles } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ItemAsideHref = (props) => {
    const { icon, title, to } = props

    return (<Href target={"_blank"} href={to}>
        <RedTooltip arrow title={title} placement="right">
            <Container>{icon}</Container>
        </RedTooltip>
    </Href>
    )
}

export default ItemAsideHref;

const RedTooltip = withStyles((theme) => ({
    arrow: {
        color: "#303030",
    },
    tooltip: {
        backgroundColor: "#303030",
        color: theme.palette.common.white,
        boxShadow: theme.shadows[1],
        fontSize: 14,
        padding: "0.2em 0.5em",
    },
}))(Tooltip);

const Container = styled.div`
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .3s ease;
    svg, span {
            color: #fafafa;
    }
    span {
        color: #bfbfbf;
    }

    :hover {
        background-color: #545454;
        border-right: 2px solid #fafafa;
    }
`

const Href = styled.a`

`