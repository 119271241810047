import React from "react";
import { Redirect, Switch } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Profile from "./pages/Profile";
import PrivateRoute from "./routes/PrivateRoute";
import PublicRoute from "./routes/PublicRoute";

export const Routes = () => {

    return (
        <Switch>
            <PublicRoute path="/auth/login" component={Login}/>
            <PrivateRoute path="/home" exact component={Home}/>
            <PrivateRoute path="/profile" exact component={Profile}/>
            <PrivateRoute path="/logout" exact component={Logout}/>
            <Redirect path="/" to="/auth/login" exact/>
        </Switch>
    )
}