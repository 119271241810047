import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loading from "../components/common/Loading";
import Authorization from "../components/ui/molecules/home/Authorization";
import Mount from "../components/ui/molecules/home/Mount";
import DataCard from "../components/ui/molecules/home/DataCard";
import Profile from "../components/ui/molecules/home/Profile";
import { getOrdersService } from "../services/sis.api";
import { DataGrid } from '@material-ui/data-grid';
import TableGen from "../components/common/Table";
import { Button, Dialog, Input } from "@material-ui/core";
import DialogInfo from "../components/ui/organisms/home/DialogInfo";
import ErrorMessage from "../components/ui/templates/error/ErrorMessage";
import useAuth from "../hooks/useAuth";
import authorize from "../assets/images/icon-authorize.png";
import teacher from "../assets/images/icon-teacher.png";
import payment from "../assets/images/icon-payment.png";

const Home = () => {

    const [orders, setorders] = useState(null)
    const [open, setOpen] = useState(false);
    const [data, setData] = useState(null)

    const [values, setValues] = useState([]);
    const [search, setSearch] = useState("");

    const token = useAuth()?.user?.token

    const columns = [
        { id: 'type_id', label: 'Tipo' },
        { id: 'partner_ref', label: 'Referencia' },
        { id: 'date_payment', label: 'Fecha Aviso' },
        { id: 'date_e_payment', label: 'Fecha de Pago' },
        { id: 'amount', label: 'Monto' },
        { id: 'state', label: 'Estado' },
        { id: 'info', label: '+Info' },
    ]

    useEffect(() => {
        getOrders()
    }, [])

    useEffect(() => {
        if (orders !== null && orders !== false) {
            setValues(
                orders.filter((propsValue) => {
                    return propsValue.partner_ref
                        .toLowerCase()
                        .includes(search.toLowerCase());
                })
            );
        }
        
    }, [orders, search])


    const handleClickOpen = (data) => {
        setOpen(true);
        setData(data)
    };

    const handleClose = () => {
        setOpen(false);
    };

    const getOrders = async () => {
        const response = await getOrdersService("", "", token)
        if (response !== false) {
            let result = []
            response.ordenes.map((row, i) => {
                return result.push({ 
                id: i, info: <ButtonFake onClick={() => handleClickOpen(row)}>+info</ButtonFake>, ...row, 
                state: <State state={row.state}>{row.state}</State>, 
                amount: row.amount_total > 0 ? `${row.currency_id} ${row.amount_total}` : `${row.currency_id} ${row.amount_totalD}`})
            })
            setorders(result)
        }
        else{
            setorders(false)
        }
    }

    const MountFunction = (orders) => {
        // console.log(orders);
        let smontoAC = 0.00;
        let smontoACD = 0.00;
        let scantidad = 0;
        if (orders !== undefined) {
            orders.forEach(element => {
                switch (element.state.props.state) {
                    case 'A cobrar':
                        if (element.currency_id === 'USD') {
                            smontoACD = smontoACD + element.amount_totalD;
                            // console.log(element.amount_totalD);

                        } else {
                            smontoAC = smontoAC + element.amount_total;
                        }
                        break;
                    case 'A autorizar':
                        scantidad = scantidad + 1;
                        break;
                    default:
                        break;
                }
            });
        }
        // console.log({ scantidad, smontoAC, smontoACD })
        return { scantidad, smontoAC, smontoACD }
    }

    function handleChange(e) {
        setSearch(e.target.value);
    }

    if (orders === null) {
        return <Loading />
    }

    if (orders === false) {
        return <ErrorMessage/>
    }

    return <Container>
        <Header>
            <DataCard component={<Profile />} img={teacher} />
            <DataCard component={<Authorization value={ MountFunction(orders).scantidad} />} img={authorize} />
            <DataCard component={<Mount value={ MountFunction(orders)} />} img={payment} />
        </Header>
        <Body>
            <ContainerInput>
                <InputFake onChange={handleChange} value={search} placeholder="Filtro de busqueda"/>
            </ContainerInput>
            <TableGen pageSize={15} columns={columns} rows={values} />
        </Body>
        <Dialog maxWidth={"xl"} onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
            <DialogInfo handleClose={handleClose} data={data}/>
        </Dialog>
    </Container>
}

export default Home;

const Container = styled.div`
    width: 100%;
    height: 100%;
    /* background-color: red; */
`

const ContainerInput = styled.div`
    width: 100%;
    height: 100%;
    margin: 15px;
    /* background-color: red; */
`

const Header = styled.div`
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }
`

const Body = styled.div`
    width: 100%;
    /* min-height: 300px; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 15px;
    flex-direction: column;
`

const InputFake = styled(Input)`
    width: 350px;
`

const ButtonFake = styled.button`
    color: white;
    background-color: #de4d2c;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-weight: bolder;
    cursor: pointer;
    transition: .3s ease all;
    border-radius: 2px;
    :hover{
        background-color: #8a4a3b;
    }
`

const State = styled.div`
    padding: 7px;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 12px;
    width: 50px;
    background-color: ${props => {
        if(props.state === "Pagado"){
            return "#5f9e20"
        }
        if(props.state === "A autorizar"){
            return "#44afc9"
        }
        if(props.state === "A cobrar"){
            return "#d68149"
        }
    }};
`