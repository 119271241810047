import React from "react";
import styled from "styled-components";
import { getItemJson } from "../../../../helpers/sessionstorage.helper";

const Authorization = (props) => {

    const { value } = props

    return <>
        <Title>Por autorizar</Title>
        <Data>Cantidad: {value}</Data>
    </>
}

export default Authorization

const Title = styled.h4`
    font-size: 14px;
    font-weight: 800;
    color: #de4d2c;
`

const Data = styled.h4`
    font-size: 16px;
    font-weight: 800;
    color: #303030;
    margin-top: 5px;
`