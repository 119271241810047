import React from "react";
import styled from "styled-components";
import { Markup } from 'interweave';

const AdditionalInfo = props => {
    const { id, date, note } = props

    return <Container>
        <ul>
            <li>N° de Orden: {id}</li>
            <li>Fecha de creacion de Orden: {date}</li>
            <li>Comentario adicional: </li>
            <Markup content={note} />
        </ul>
    </Container>
}

export default AdditionalInfo;

const Container = styled.div`
    padding: 20px;
    width: 100%;
`