import axios from "axios";

const SISURL = process.env.REACT_APP_SISAPI

const ACROPOLIS = "/v1/acropolis"
const FACULTY = "/v1/faculty"


export const loginService = async (data) => {
    const URL = `${SISURL}${FACULTY}/auth/login`

    try {
        const result = await axios.post(URL,data)
        return result.data.response_data
    } catch (error) {
        return {
            status: true,
            error
        }
    }
}

export const getOrdersService = async (fechai, fechaf , token) => {

    const URL = `${SISURL}/ordenes`

    const headers = {Authorization: `Bearer ${token}`}
    const data = {fechai, fechaf};


    try {
        const result = await axios.post(URL, data, {headers})
        return result.data
    } catch (error) {
        return false
    }
}

export const getStudentsSisService = async (oc) => {
    const URL = `${SISURL}/ordensis`

    const data = {oc};

    try {
        const result = await axios.post(URL,data)
        return result.data
    } catch (error) {
        return false
    }
}


export const postContactService = async (data) => {
    const URL = `${SISURL}/contacto_oc`

    const body = {
        mensaje: data.text,
        tipo: data.typeId,
        asunto: data.type,
        contacto: data.contact
    };

    try {
        const result = await axios.post(URL,body)
        return result.data
    } catch (error) {
        return {
            status: true,
            error
        }
    }
}