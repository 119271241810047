
import React from "react";
import styled from "styled-components";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ItemAsideMobile from "../../ui/molecules/aside/ItemAsideMobile";

const AsideMobile = (props) => {

    const { showMenu } = props

    return <Container>
        <CloseMenu onClick={showMenu}><HighlightOffIcon/></CloseMenu>
        <List>
            <ItemAsideMobile to={"/home"} title={"Inicio"}/>
            <ItemAsideMobile to={"/profile"} title={"Mi perfil"}/>
            <ItemAsideMobile type="a" to={"https://aden.instructure.com/login/openid_connect"} title={"Canvas"}/>
        </List>
    </Container>
}

export default AsideMobile;

const Container = styled.div`
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    background-color: #303030;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
`

const List = styled.div`
    width: 80%;
    height: 80vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
`

const CloseMenu = styled.div`
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
    svg{
        color: white;
    }
`