import React from "react";
import styled from "styled-components";
import FormLogin from "../components/ui/organisms/login/FormLogin";

import bg from "../assets/images/bglogin.jpg";
import logo from "../assets/images/logo-aden-faculty.png";

const Login = () => {
    return <Container>
        <ImgBg src={bg} />
        <LeftWrapper>
            <FormLogin/>
        </LeftWrapper>
        <RightWrapper>
            <Logo src={logo}/>
        </RightWrapper>
    </Container>
}

export default Login;

const Container = styled.div`
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: repeat(2,50%);
    grid-template-areas: "left right";
    overflow-y: hidden;
    @media screen and (max-width: 768px) {
        grid-template-areas:    "right"
                                "left"
        ;
        height: auto;
        grid-template-columns: 100%;
    }
    `

const LeftWrapper = styled.div`
    grid-area: left;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    z-index: 2;
    border-right: 5px solid #de4d2c;
    background-color: rgba(255,255,255,.3);
    @media screen and (max-width: 768px) {
        border-right: none;
        height: calc(100vh - 200px);
    }
`

const RightWrapper = styled.div`
    grid-area: right;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,.8);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        height: 200px;
    }
`

const Img = styled.img`
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
    z-index: 3;
`

const ImgBg = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
`

const Logo = styled.img`
    width: 450px;
    height: auto;
    margin: 30px 0;
    z-index: 2;
    @media screen and (max-width: 768px) {
        width: 250px;
    }
`
