import React from "react";
import styled from "styled-components";
import { getItemJson } from "../../../../helpers/sessionstorage.helper";

const Profile = () => {

    const user = getItemJson("faculty-session").profile_data

    return <>
        <Title>Nombre del profesor</Title>
        <Data>{user.name}</Data>
    </>
}

export default Profile

const Title = styled.h4`
    font-size: 14px;
    font-weight: 800;
    color: #de4d2c;
`

const Data = styled.h4`
    font-size: 16px;
    font-weight: 800;
    color: #303030;
    margin-top: 5px;
`