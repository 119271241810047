import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import styled from "styled-components";
import { loginService } from "../../../../services/sis.api";
import * as encode from "nodejs-base64-encode"
import { useHistory } from "react-router-dom";
import Loading from "../../../common/Loading";
import { saveItemJson } from "../../../../helpers/sessionstorage.helper";
import useAuth from "../../../../hooks/useAuth";
import { useSnackBar } from "../../../../hooks/useSnackBar";
import { SnackBarGenerico } from "../../../common/SnackBarGenerico";

const FormLogin = () => {

    const [user, setuser] = useState(null)
    const [password, setpassword] = useState(null)
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();
    const userAuth = useAuth()
    const [loading, setloading] = useState(false)

    let history = useHistory();

    const handleSetUser = e => {
        setuser(e.target.value)
    }

    const handleSetPass = e => {
        setpassword(e.target.value)
    }

    const login = async () => {
        setloading(true)
        const data = {
            user,
            password
        }
        const response = await loginService(data)
        if (response.token) {
            await userAuth.login(response)             
        }
        if (response.status) {
            openSnackBar(
                "error",
                String(response.error.response.data.error)
            );
        }
        setloading(false)
    }

    return <Container>
        <WrapperField>
            <Input type="email" value={user} onChange={handleSetUser} id="outlined-basic" label="Usuario" variant="outlined" />
        </WrapperField>
        <WrapperField>
            <Input type="password" value={password} onChange={handleSetPass} id="outlined-basic" label="Contraseña" variant="outlined" />
        </WrapperField>
        <WrapperField>
                {!loading ? (
                    <ButtonFake onClick={login} disabled={user === null || password === null ? true : false}>INGRESAR</ButtonFake>
                ) :
                (
                    <Loading />
                )}
        </WrapperField>
        <WrapperField>
            <ButtonPass target={"_blank"} href="https://fa.aden.org/password/forgot?tenantId=a988e21d-96c6-6310-28e5-d8b43c06852b&client_id=2c54fc21-43b7-4c96-ba29-a4a66fd54342&nonce=&redirect_uri=https://faculty.aden.org&response_type=code&scope=&state=S8pPorNxtMGAc8Agg5gGMmVihCakJz4_IXyfgaWOTxQ&timezone=America%2FArgentina%2FBuenos_Aires&metaData.device.name=Windows%20Chrome&metaData.device.type=BROWSER">OLVIDE MI CONTRASEÑA</ButtonPass>
        </WrapperField>
        <SnackBarGenerico
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </Container>
}

export default FormLogin;

const Container = styled.div`
    width: 50%;
    height: auto;
    padding: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const WrapperField = styled.div`
    width: 100%;
    margin: 15px 0;
`

const Input = styled(TextField)`
    width: 100%;
    margin: 0 15px;
    color: white;
    background-color: white;
    border-radius: 3px;
`

const ButtonFake = styled.button`
    color: white;
    background-color: #de4d2c;
    padding: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 3px;
    transition: all .3s ease;
    cursor: pointer;
    :disabled{
        background-color: #404040;
        cursor: inherit;
    }
    :hover{
        background-color: #a33e27;
        :disabled{
            background-color: #404040;
            cursor: inherit;
    }
    }
`

const ButtonPass = styled.a`
    color: white;
    text-decoration: none;
    background-color: #545454;
    padding: 10px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-weight: 700;
    font-size: 14px;
    border-radius: 3px;
    transition: all .3s ease;
    cursor: pointer;
    :hover{
        background-color: #5e5e5e;
    }
`