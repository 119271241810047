import React from "react";
import styled from "styled-components";

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import DashboardSharpIcon from '@material-ui/icons/DashboardSharp';

import ItemAside from "../../ui/molecules/aside/ItemAside";
import ItemAsideHref from "../../ui/molecules/aside/ItemAsideHref";

const Aside = () => {
    return <Container>
        <TopWrapper>
            <ItemAside to={"/home"} title={"Inicio"} icon={<HomeIcon/>}/>
            <ItemAside to={"/profile"} title={"Mi Perfil"} icon={<AccountBoxIcon/>}/>
            <ItemAsideHref to={"https://aden.instructure.com/login/openid_connect"} title={"Dashboard Canvas"} icon={<DashboardSharpIcon/>}/>
        </TopWrapper>
        <BottomWrapper>
            <ItemAside to={"/logout"} title={"Cerrar Sesión"} icon={<ExitToAppIcon/>}/>
        </BottomWrapper>
    </Container>
}

export default Aside

const Container = styled.div`
    width: 80px;
    height: 100vh;
    background-color: #303030;
    -webkit-box-shadow: 4px 0px 11px -3px #313131; 
    box-shadow: 4px 0px 11px -3px #313131;
`

const TopWrapper = styled.div`
    padding-top: 80px; 
    height: 80%;
`

const BottomWrapper = styled.div`
    height: 20% ;
`