import React from "react";
import styled from "styled-components";

const ErrorMessage = () => {
    return <Container>
        <Title>No se pudo cargar la página</Title>
        <ButtonFake onClick={() => window.location.reload()}>Reintentar</ButtonFake>
    </Container>
}

export default ErrorMessage

const Container = styled.div`
    width: 100%;
    height: 60vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Title = styled.div`
    font-size: 22px;
    color: #de4d2c;
    font-weight: bolder;
`

const ButtonFake = styled.button`
    color: white;
    background-color: #de4d2c;
    padding: 10px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 3px;
    transition: all .3s ease;
    cursor: pointer;
    margin-top: 30px;
    :disabled{
        background-color: #878382;
        cursor: inherit;
    }
    :hover{
        background-color: #a33e27;
        :disabled{
            background-color: #878382;
            cursor: inherit;
    }
    }
`