import logo from './logo.svg';
import './App.css';
import { Routes } from './Routes';
import { BrowserRouter } from "react-router-dom";
import AuthProvider from './context/auth/AuthProvider';

function App() {
  console.log(process.env.REACT_APP_NAME);
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
