import React, { useEffect, useState } from "react";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styled from "styled-components";
import useAuth from "../hooks/useAuth";
import { getDataUserService } from "../services/repositorio.api";
import { useSnackBar } from "../hooks/useSnackBar";
import { SnackBarGenerico } from "../components/common/SnackBarGenerico";
import FormPersional from "../components/ui/organisms/profile/FormPersonal";
import Loading from "../components/common/Loading";
import FormAcademic from "../components/ui/organisms/profile/FormAcademic";
import Documentation from "../components/ui/organisms/profile/Documentation";


const Profile = () => {

    const [value, setValue] = useState(0);
    const [userRepo, setuserRepo] = useState(null)
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();
    const user = useAuth().user.profile_data

    useEffect(() => {
        if (userRepo === null) {
            getDataRepo(user.repositorio_id)
        }
    }, [userRepo])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getDataRepo = async (partner_id) => {
        const response = await getDataUserService(partner_id)
        if (!response.status) {
            setuserRepo(response)
        }
        else {
            openSnackBar(
                "error",
                response.error
            );
        }
    }

    if (userRepo === null) {
        return <>
            <SnackBarGenerico
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
            />
            <Loading/>
        </>
    }

    return <>
        <AppBar position="static">
            <TabsFake value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Información Personal" />
                <Tab label="Información Educativa" />
                <Tab label="Documentación" />
            </TabsFake>
        </AppBar>
        <TabPanel value={value} index={0}>
            <FormPersional repo_id={user.repositorio_id} id={user.partner_id} data={userRepo.info_personal}/>
        </TabPanel>
        <TabPanel value={value} index={1}>
            <FormAcademic repo_id={user.repositorio_id} id={user.partner_id} data={userRepo.info_educativa}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
            <Documentation repo_id={user.repositorio_id} data={userRepo.documentacion}/>
        </TabPanel>
        <SnackBarGenerico
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </>
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export default Profile;

const TabsFake = styled(Tabs)`
    background-color: #303030;
    .PrivateTabIndicator-colorSecondary-7 {
        background-color: #de4d2c
    }
`