import { InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import useAuth from "../../../../hooks/useAuth";
import { useSnackBar } from "../../../../hooks/useSnackBar";
import { getTypesDocumentsService, postDocumentService } from "../../../../services/repositorio.api";
import Loading from "../../../common/Loading";
import { SnackBarGenerico } from "../../../common/SnackBarGenerico";

const FormDocument = props => {
    const { handleClose, repo_id } = props
    // State
    const [typesDocuments, setTypesDocuments] = useState(null)
    const [typedocument, settypedocument] = useState(null)
    const [description, setdescription] = useState(null)
    const [institution, setinstitution] = useState(null)
    const [year, setyear] = useState(null)
    const [document, setdocument] = useState(null)

    const [loading, setloading] = useState(null)


    // Hooks
    const user = useAuth().user.profile_data
    const [snackBar, closeSnackBar, openSnackBar] = useSnackBar();

    // Effects
    useEffect(() => {
        if (typesDocuments === null) {
            getTypesDocuments()
        }
    }, [typesDocuments])

    // Functions
    const getTypesDocuments = async () => {
        const response = await getTypesDocumentsService()
        setTypesDocuments(response)
    }

    const handleFile = async (e) => {
        const result = await toBase64(e.target.files[0])
        setdocument(result)
    }

    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleSubmit = async () => {
        setloading(true)
        const newDocument = document.split("base64,")
        const data = {
            tipo_documento: typedocument,
            descripcion: description,
            institucion: institution,
            anio_realizacion: year,
            documento: newDocument[1]
        }
        const response = await postDocumentService(repo_id, data)
        if (response.status) {
            openSnackBar(
                "error",
                String(response.error)
            );
        }
        else{
            openSnackBar(
                "success",
                response
            );
        }
        setloading(null)
        setTimeout(() => {
            handleClose()
        }, 6000);
    }

    return <>
        <FormGroup>
            <FormGroup>
                <InputLabel>Tipo de documento</InputLabel>
                <SelectFake
                    value={typedocument}
                    onChange={e => settypedocument(e.target.value)}
                >
                    {typesDocuments && (
                        typesDocuments.map(item => <MenuItem value={item.id}>{item.nombre}</MenuItem>)
                    )}
                </SelectFake>
            </FormGroup>
            <FormGroup>
                <TextFieldFake
                    label="Documento"
                    type="file"
                    onChange={handleFile}
                />
            </FormGroup>
            <FormGroup>
                <TextFieldFake
                    value={institution}
                    label="Institución"
                    onChange={e => setinstitution(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <TextFieldFake
                    value={description}
                    label="Descripcion"
                    // multiline
                    rowsMax={4}
                    onChange={e => setdescription(e.target.value)}
                />
            </FormGroup>
            <FormGroup>
                <TextFieldFake
                    value={year}
                    label="Año de realizacion"
                    onChange={e => setyear(e.target.value)}
                    type="number"
                />
            </FormGroup>
            <ZoneSubmit>
                {loading === null ?
                    (<ButtonFake
                        disabled={
                            typedocument !== null && typedocument !== "" &&
                                description !== null && description !== "" &&
                                institution !== null && institution !== "" &&
                                year !== null && year !== "" &&
                                document !== null && document !== "" ? false : true
                        }
                        onClick={handleSubmit}>GUARDAR</ButtonFake>)
                    :
                    (<Loading />)
                }
            </ZoneSubmit>
        </FormGroup>
        <SnackBarGenerico
            show={snackBar.show}
            handleClose={closeSnackBar}
            message={snackBar.message}
            variant={snackBar.variant}
        />
    </>
}

export default FormDocument

const FormGroup = styled.div`
    padding: 15px 0;
`

const TextFieldFake = styled(TextField)`
    width: 100%;
`

const SelectFake = styled(Select)`
    width: 100%;
    margin-top: 15px;
`

const ZoneSubmit = styled.div`
    width: 100%;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
`

const ButtonFake = styled.button`
    color: white;
    background-color: #de4d2c;
    padding: 9px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 3px;
    transition: all .3s ease;
    cursor: pointer;
    :disabled{
        background-color: #404040;
        cursor: inherit;
    }
    :hover{
        background-color: #a33e27;
        :disabled{
            background-color: #404040;
            cursor: inherit;
    }
    }
`