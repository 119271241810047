import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Aside from "../aside/Aside";
import AsideMobile from "../aside/AsideMobile";
import Navbar from "../navbar/Navbar";

const Layout = (props) => {
	// STATE
	const [menu, setMenu] = useState(false);

	// FUNCTIONS
	function showMenu() {
		setMenu(!menu);
	}

	function closeMenu() {
		setMenu(false);
	}

	// RETURN
	return (
		<>
            <AsideContainer>
                {/* <Aside */}
				{!menu ? (<Aside/>) : (<AsideMobile showMenu={showMenu}/>)}
            </AsideContainer>
			{/* <Overlay menu={menu}></Overlay> */}
            <Navbar showMenu={showMenu}/>
			<Main>
				{props.children}
				{/* <Copyright></Copyright> */}
			</Main>
		</>
	);
};

export default Layout;


const Main = styled.main`
	padding: calc(2rem + 70px) 16rem 2rem calc(16rem + 70px);
	overflow: ${(props) => props.menu && "hidden"};

	@media (max-width: 768px) {
		padding: 5rem 1rem 1rem 1rem;
		width: calc(100% - 4rem);
	}
`;

const AsideContainer = styled.div`
    position: fixed;
	z-index: 9;
    left: 0;
	@media screen and (max-width: 768px) {
        left: -80px;
    }
`