import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { getStudentsSisService } from "../../../../services/sis.api";
import Loading from "../../../common/Loading";
import TableGen from "../../../common/Table";

const SisData = props => {
    const { id } = props

    const [data, setdata] = useState(null)

    const columns = [
        { id: 'codpersona', label: 'Cod. SIS' },
        { id: 'alumno', label: 'Alumno' },
        { id: 'fechamodif', label: 'Fecha Mod.' },
        { id: 'estado', label: 'Estado' },
    ]

    useEffect(() => {
        if (data === null) {
            getStudentsSis(id)
        }
    }, [data])

    const getStudentsSis = async id => {
        const response = await getStudentsSisService(id)
        if (response !== false) {
            setdata(response)
        }
        else{
            setdata([])
        }
    }

    if (data === null) {
        return <Loading/>
    }

    return <Container>
        <TableGen pageSize={15} columns={columns} rows={data} />
    </Container>
}

export default SisData;

const Container = styled.div`
    padding: 0px;
    width: 100%;
`