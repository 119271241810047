import React, { useState } from "react";
import styled from "styled-components";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Button, TextField } from "@material-ui/core";
import { postContactService } from "../../../../services/sis.api";
import Loading from "../../../common/Loading";

const FormReport = (props) => {
    const { typeId, contact, id } = props

    const [type, setType] = useState("consulta");
    const [text, setText] = useState("");

    const [loading, setloading] = useState(false)

    const handleChangeRadio = (event) => {
        setType(event.target.value);
    };

    const handleChangeText = (event) => {
        setText(event.target.value);
    };

    const handleSubmit = async () => {
        setloading(true)
        const data = {type: `${type} en OC: ${id}`,text,typeId,contact}
        const response = await postContactService(data);
        if (response !== false) {
            setText("");
        }
        setloading(false)
    }
    

    return <Container>
            <RadioGroup aria-label="gender" name="gender1" value={type} onChange={handleChangeRadio}>
                <FormControlLabel value="error" control={<Radio />} label="Error de carga" />
                <FormControlLabel value="consulta" control={<Radio />} label="Consulta" />
            </RadioGroup>
            <TextFieldFake
                onChange={handleChangeText}
                id="outlined-multiline-static"
                label={`Deja tu ${type === "error" ? "problema" : "consulta"}`}
                multiline
                rows={4}
                defaultValue={text}
                value={text}
                variant="outlined"
            />
            {!loading ? (
                <ButtonFake onClick={handleSubmit} disabled={text !== "" ? false : true}>Enviar {type === "error" ? "problema" : "consulta"}</ButtonFake>
            ): (
                <Loading/>
            ) }
    </Container>
}

export default FormReport

const Container = styled.div`
    width: 100%;
    padding: 5px;
`

const TextFieldFake = styled(TextField)`
    width: 100%;
    margin-top: 15px;
`

const ButtonFake = styled.button`
    color: white;
    background-color: #de4d2c;
    padding: 15px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    font-weight: 700;
    font-size: 18px;
    border-radius: 3px;
    transition: all .3s ease;
    cursor: pointer;
    margin-top: 15px;
    :disabled{
        background-color: #878382;
        cursor: inherit;
    }
    :hover{
        background-color: #a33e27;
        :disabled{
            background-color: #878382;
            cursor: inherit;
        }
    }
`